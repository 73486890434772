export const createMapFromObject = <T>(obj: Record<string, T>): Map<string, T> => {
  return new Map(Object.entries(obj));
};

export const appendObjectFields = (object: Record<string, string>, appendValue: string): Record<string, string> => {
  const map = createMapFromObject(object);
  map.forEach((value, key) => {
    map.set(key, `${appendValue} ${value}`);
  });

  return Object.fromEntries(map.entries());
};

export const formatNumber = (money: string | number | null) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(money));
};

export const formatDate = (timeData: Date | string | null): string => {
  const dayjs = useDayjs();
  return timeData ? dayjs(timeData).format('MM/DD/YYYY hh:mm A') : '';
};

export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const capitalizeAll = (value: string) => {
  return value
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
};

export function toMoney(value: number): string {
  if (value === 0) {
    return 'Free!';
  }

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(value));
}

export function numberFormatWithFractionDigits(
  amount: number | string | null,
  minFractionDigits: number,
  maxFractionDigits: number,
): string {
  if (!amount) {
    return '';
  }

  const number = typeof amount === 'string' ? Number(amount) : amount;

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: maxFractionDigits,
  }).format(number);
}

export function inputNumberIntegerFormat(amount: number | string | null): string {
  return numberFormatWithFractionDigits(amount, 0, 0);
}

export function inputNumberMoneyFormat(amount: number | string | null): string {
  return numberFormatWithFractionDigits(amount, 2, 2);
}

export function inputNumberPercentFormat(amount: number | string | null): string {
  return numberFormatWithFractionDigits(amount, 0, 3);
}

export function moneyFormat(amount: number | string): string {
  const money = typeof amount === 'string' ? Number(amount) : amount;

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(money);
}

export function percentFormat(amount: number | string): string {
  const percentValue = typeof amount === 'string' ? Number(amount) : amount;

  return new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 3 }).format(percentValue);
}

export function round(value: number, decimalPoint: number = 2): number {
  const scale: number = 10 ** decimalPoint;

  return Math.round(value * scale) / scale;
}
